<template>
  <section class="intro">
    <div class="intro-content">
      <h1>{{ $t('intro.headline') }}</h1>

      <div class="intro-text">
        <Markdown :source="$t('intro.content')" />
      </div>
      <div class="button-start">
        <VButton
          router-link
          as="a"
          :to="{ path: thesesPath }"
        >
          {{ $t('intro.button') }}
        </VButton>
      </div>
    </div>
  </section>
</template>

<script>
import { getTranslatedUrl } from '@/i18n/helper';
import Markdown from '@/components/markdown.vue';
import VButton from '../../../components/Button.vue';

export default {
  name: 'Intro',
  components: {
    Markdown,
    VButton,
  },

  computed: {
    thesesPath() {
      return getTranslatedUrl(this.$i18n, 'theses');
    },
  },
};
</script>

<style lang="postcss" scoped>
  h1,
  p {
    font-weight: 700;
    margin-bottom: var(--base-gap);
  }

  .btn {
    padding: 15px 45px;
    stroke: 0px;
    border: none;
    background-color: #041749;
    box-shadow: 1px 1px 3.3px 1px rgba(0, 0, 0, 0.25);
    color: white;
 }
  .button-start {
    display: flex;
    justify-content: center;
  }
  .warning {
    margin-top: var(--base-gap);
  }

  p.small {
    font-size: var(--font-size-small);
  }

  .intro {
    position: relative;
  }

  .intro-content {
    background-color: whitesmoke;
    padding: 1em;
    border-radius: 15px;
    box-shadow: 0px 2px 3.3px 1px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 1;
  }

  .intro-text {
    margin-bottom: var(--base-gap);
  }
</style>
