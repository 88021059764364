import { createApp, configureCompat } from 'vue';

import router from '@/router';
import i18n from '@/i18n';
import storage from '@/helper/storage';
import { EMAIL } from '@/config';
import App from '@/components/App.vue';
import VueFeather from 'vue-feather';
import FloatingVue from 'floating-vue';

import '@/registerServiceWorker';

const app = createApp(App);

app.component(VueFeather.name, VueFeather);
app.use(FloatingVue);

app.config.globalProperties.$storage = storage; // assumes `storage` is a global property

app.use(router);
app.use(i18n);

app.provide('email', EMAIL);
app.provide('backupStorage', {
  answers: undefined,
  emphasized: undefined,
});

configureCompat({
  MODE: 2,
  RENDER_FUNCTION: false,
});

app.mount('#app');
