<template>
  <div>
    <svg
      width="800"
      height="300"
      viewBox="0 0 800 300"
    >
      <g clip-path="url(#clip0_35_3)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M73.528 72.925a6.64 6.64 0 00-4.695-1.945l-17.436.009a6.64 6.64 0 00-6.64 6.64v24.085h30.725l-.01-24.094c0-1.761-.699-3.45-1.944-4.695z"
          fill="#fff"
        />
        <path
          d="M75.473 108.5H44.748l.009 85.38 6.56 13.12 1.121 2.242 7.681 15.363 7.682-15.363L68.92 207l6.561-13.12-.01-85.38zM107 74.8h99.2v28h-35.8V222h-29V102.8H107v-28zM205.673 222l66.4-153h1.6l66.4 153h-33.6l-42.4-107.6 21-14.4-50.8 122h-28.6zm45.4-53.2h44.2l10.2 24.4h-63.4l9-24.4zM361.531 222V68.8h.2l81.8 116-12.4-2.8 81.6-113.2h.4V222h-29v-87.8l1.8 15-49.8 70.8h-.4l-51.2-70.8 5-13.8V222h-28zm173.049 0l66.4-153h1.6l66.4 153h-33.6l-42.4-107.6 21-14.4-50.8 122h-28.6zm45.4-53.2h44.2l10.2 24.4h-63.4l9-24.4zm88.543-94h99.2v28h-35.8V222h-29V102.8h-34.4v-28z"
          fill="#fff"
        />
        <path
          d="M731.5 271.5h-197"
          stroke="#CD212A"
          stroke-width="15"
        />
        <path
          d="M731.5 257h-284"
          stroke="#fff"
          stroke-width="15"
        />
        <path
          d="M731.5 242H362"
          stroke="#008C45"
          stroke-width="15"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_3">
          <path
            fill="#fff"
            d="M0 0h800v300H0z"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
