<template>
  <div
    id="app"
    :class="{ 'is-embedded': isEmbedded}"
  >
    <header
      v-if="!isEmbedded"
      class="app-header"
    >
      <router-link :to="{ path: `/${$i18n.locale}/` }">
        <!-- <img
          class="header-logo"
          alt="Itamat Logo"
          :src="itamatLogo"
        > -->
        <ItamatLogo class="header-logo" />
      </router-link>
      <!--
      <AppMenu
        :main="topMenu"
        :languages="languages"
      /> -->
    </header>

    <main id="main">
      <router-view />
    </main>

    <AppFooter
      v-if="!isEmbedded"
      :menu="subMenu"
      :social="socialMedia"
    />
  </div>
</template>

<script>
// import AppMenu from '@/components/AppMenu.vue';
import { SUPPORTED_LOCALES } from '@/config';
import { getTranslatedUrl } from '@/i18n/helper';
import ItamatLogo from '@/svgs/ItamatLogo.vue';
import AppFooter from './AppFooter.vue';

export default {
  name: 'App',
  components: {
    // AppMenu,
    AppFooter,
    ItamatLogo,
  },
  data() {
    return {
      // itamatLogo: require('@/assets/svg/itamat-logo.svg'),
      languages: SUPPORTED_LOCALES.map(([locale, language]) => ({
        locale,
        language,
      })),
    };
  },

  computed: {
    topMenu() {
      return [
        {
          label: this.$t('meta.topMenu.index'),
          icon: 'home',
          route: { path: `/${this.$i18n.locale}/` },
        },
        {
          label: this.$t('meta.topMenu.faq'),
          icon: 'help-circle',
          route: { path: getTranslatedUrl(this.$i18n, 'faq') },
        },
        {
          label: this.$t('meta.topMenu.about'),
          icon: 'users',
          route: { path: getTranslatedUrl(this.$i18n, 'about') },
        },
        {
          label: this.$t('meta.topMenu.contact'),
          icon: 'mail',
          route: { path: getTranslatedUrl(this.$i18n, 'contact') },
        },
      ];
    },
    subMenu() {
      return [
        {
          label: this.$t('meta.footerMenu.privacy'),
          route: { path: getTranslatedUrl(this.$i18n, 'privacy') },
        },
      ];
    },
    socialMedia() {
      return [
        {
          label: 'twitter',
          icon: 'twitter',
          message: {
            text: this.$t('meta.socialMedia.twitter'),
            hashtags: 'Itamat,Itamat2024,elezioni2024',
          },
        },
        {
          label: 'clipboard',
          icon: 'clipboard',
          message: this.$t('meta.socialMedia.clipboard'),
        },
      ];
    },
    isEmbedded() {
      return this.$route.query.embedded && this.$route.query.embedded === 'iframe';
    },
  },

  beforeCreate() {
    document.querySelector('.go-to-main').textContent = this.$t('meta.skipToMain');
  },
  methods: {
    updateConsent() {
      //        setCookie(GA_COOKIE_NAME, consent)
      this.showConsentLayer = false;
    },
  },
};
</script>
<style lang="postcss">
  @import 'normalize.css';
  @import '../styles/fonts';
  @import '../styles/layout';

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html,
  body {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  body {
    color: var(--prussian-blue);
    font-family: var(--font-roboto);
    font-weight: normal;
    font-size: var(--font-size-base);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    background-color: #0a36af;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    @media (max-width: 768px) {
      background-image: none;
    }
  }

  .go-to-main {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
    background: var(--prussian-blue);
    left: 0;
    top: 0;
    padding: calc(var(--small-gap) / 2);
    z-index: 100;
    font-weight: 500;
    font-size: var(--font-size-medium);

    &:focus {
      color: var(--white) !important;
      clip: auto;
      clip-path: none;
      height: auto;
      overflow: auto;
      position: absolute;
      width: auto;
    }
  }

  h1 {
    font-size: var(--font-size-lmedium);
    line-height: 110%;
    font-weight: 300;
    margin: 0;
  }

  h2 {
    font-size: var(--font-size-large);
    color: var(--blue-green);

    @media (max-width: 768px) {
      font-size: calc(var(--font-size-large) - 50%);
    }
  }

  p {
    line-height: 150%;
    font-size: var(--font-size-xmedium);

    @media (max-width: 768px) {
      font-size: var(--font-size-medium);
    }
  }

  a {
    color: var(--prussian-blue);
    text-decoration: none;
    transition: color 150ms var(--ease-out-back);

    &:not(.btn):hover {
      text-decoration: underline;
    }

    &:not(.btn):focus {
      outline: 2px dashed var(--orange);
      color: black;
    }
  }

  #app {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &.is-embedded {
      padding-top: var(--small-gap);
    }

    & header {
      width: 100%;
    }
  }

  .app-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    z-index: 0;
  }

  .header-logo {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    width: auto;
    height: auto;
    max-width: 500px;
    z-index: 2;
    position: relative;

    svg {
      width: 80%;
      height: auto;
      min-width: 80px;
    }
  }

  main {
    width: 100%;
    max-width: 550px;
    padding: 0 var(--small-gap);
    position: relative;
    z-index: 1;
    margin-bottom: calc(var(--base-gap) * 2);
    padding: 1em;
    padding-top: 0px;
    border-radius: 15px;

    @media (max-width: 1050px) {
      margin-bottom: calc(var(--base-gap) * 2);
    }

    @media (max-width: 768px) {
      margin-bottom: var(--base-gap);
    }
  }
</style>
