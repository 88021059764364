import { createRouter, createWebHistory } from 'vue-router';

import intro from '@/app/intro/routes';
import itamat from '@/app/itamat/routes';
import party from '@/app/party/routes';
import about from '@/app/about/routes';
import faq from '@/app/faq/routes';
import contact from '@/app/contact/routes';
import imprint from '@/app/imprint/routes';
import privacy from '@/app/privacy/routes';
import fourzerofour from '@/app/404/routes';
import BaseLocaleRouter from '@/components/BaseLocaleRouter.vue';

import { getPageTitle } from '@/router/helper';
import { getUserSupportedLanguage } from '@/i18n/helper';

const routes = [
  {
    path: '/:locale',
    component: BaseLocaleRouter,
    children: [
      ...intro.map((route) => ({ ...route, path: `/:locale/${route.path}` })),
      ...itamat.map((route) => ({ ...route, path: `/:locale/${route.path}` })),
      ...party.map((route) => ({ ...route, path: `/:locale/${route.path}` })),
      ...about.map((route) => ({ ...route, path: `/:locale/${route.path}` })),
      ...faq.map((route) => ({ ...route, path: `/:locale/${route.path}` })),
      ...contact.map((route) => ({ ...route, path: `/:locale/${route.path}` })),
      ...imprint.map((route) => ({ ...route, path: `/:locale/${route.path}` })),
      ...privacy.map((route) => ({ ...route, path: `/:locale/${route.path}` })),
      ...fourzerofour.map((route) => ({ ...route, path: `/:locale/${route.path}` })),
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: () => `/${getUserSupportedLanguage()}`
    ,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Assume getPageTitle is a function that returns the appropriate title given a route meta's title property
  const pageTitle = to.meta.title;
  if (pageTitle) {
    document.title = getPageTitle(pageTitle);
  }

  window.scrollTo(0, 0);
  next();
});

export default router;
