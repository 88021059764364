import i18n from './i18n';
import Introduction from './components/Introduction.vue';

export default [
  {
    path: '/',
    name: 'intro',
    component: Introduction,
    meta: {
      title: {
        de: i18n.de.intro.title,
        en: i18n.en.intro.title,
      },
    },
  },
];
