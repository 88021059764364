<template>
  <footer class="appfooter">
    <ul class="footer-donation">
      <a
        class="btn"
        style="border:0px;background: #ffffff;color: #023047;text-decoration: none;display: inline-block;font-weight: 500;font-size: 110%;padding: 10px 18px;border-radius: 7px;box-shadow: 0px 2px 3.3px 1px rgba(5, 27, 87, 0.25);text-shadow: 0 1px rgba(0, 0, 0, 0.3);"
        href="https://donate.stripe.com/fZe9Cde0X0P10cE144"
      >💙 Dona</a>
    </ul>
    <ul class="footer-social">
      <a
        href="https://instagram.com/itamat.it"
        target="_blank"
      >
        <svg
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        ><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" /></svg>
      </a>
      <a
        href="https://tiktok.com/@itamat.it"
        target="_blank"
      >
        <svg
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        ><title>TikTok</title><path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z" /></svg>
      </a>
      <a
        href="https://www.facebook.com/pages/category/Youth-Organization/100224742818302/"
        target="_blank"
      >
        <svg
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        ><title>Facebook</title><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg>
      </a>
      <a
        href="https://twitter.com/itamat_it"
        target="_blank"
      >
        <svg
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        ><title>Twitter</title><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg>
      </a>
      <a
        href="https://www.linkedin.com/company/itamat/"
        target="_blank"
      >
        <svg
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        ><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" /></svg>
      </a>
      <a
        href="mailto:info@itamat.it"
        target="_blank"
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        ><path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.2393 5.43746C22.6454 3.88213 20.1241 2.79092 16.1465 3.03354C6.75409 3.60647 3.00001 10.1602 3.00001 15.214C3.00001 17.9256 3.50532 20.0202 4.423 21.6434C5.33217 23.2515 6.7049 24.5017 8.62672 25.4395C10.4506 26.3293 12.7599 26.8249 14.9889 26.9612C17.2373 27.0986 19.219 26.86 20.3914 26.431C21.1694 26.1463 22.0309 26.5462 22.3156 27.3241C22.6003 28.1021 22.2004 28.9636 21.4223 29.2483C19.7197 29.8714 17.2826 30.107 14.8058 29.9555C12.3098 29.803 9.58769 29.2463 7.31119 28.1356C4.92036 26.969 3.05861 25.3258 1.81148 23.12C0.572852 20.9291 0 18.2892 0 15.214C0 8.69449 4.87119 0.715731 15.9638 0.0390919C20.6114 -0.244395 24.02 1.03169 26.3345 3.29038C28.6144 5.51522 29.653 8.51831 29.9462 11.3786C30.1924 13.7815 29.5889 16.8644 28.2236 19.1883C27.5338 20.3624 26.5894 21.4446 25.3507 22.1012C24.0679 22.7811 22.5752 22.9455 20.9819 22.4273C20.1311 22.1504 19.3007 21.7025 18.6788 20.9069C18.4705 20.6402 18.2996 20.3528 18.1621 20.0466C16.2946 22.5816 13.0292 22.9856 10.6433 21.7382C9.37423 21.0749 8.32177 19.937 7.82245 18.3977C7.3235 16.8594 7.41656 15.0499 8.19069 13.0858C9.60506 9.49729 12.2393 7.79745 14.8658 7.17463C17.4089 6.57159 19.928 6.97164 21.4006 7.48484C22.1551 7.74779 22.571 8.55598 22.3465 9.32277C21.0473 13.7602 20.5907 16.3259 20.692 17.8362C20.7395 18.5465 20.9041 18.8823 21.0425 19.0595C21.1789 19.2338 21.413 19.4127 21.91 19.5743C22.7425 19.8452 23.3924 19.7438 23.9456 19.4505C24.5428 19.134 25.1276 18.5355 25.637 17.6685C26.6701 15.9102 27.1447 13.4694 26.9618 11.6845C26.7158 9.2842 25.8677 7.02661 24.2393 5.43746ZM17.6792 17.499C17.6656 15.6866 18.1348 13.2543 19.0376 9.98754C18.0296 9.83175 16.7837 9.80304 15.5579 10.0937C13.7589 10.5203 11.9906 11.6261 10.9817 14.1859C10.4081 15.6413 10.4338 16.725 10.6761 17.472C10.918 18.2177 11.4154 18.7566 12.0332 19.0796C13.3176 19.7511 14.9924 19.458 15.8702 18.0875C16.2628 17.4746 17.0264 17.2433 17.6792 17.499Z"
          fill="white"
        />
        </svg>
      </a>
    </ul>
    <ul class="footer-menu">
      <li style="color: whitesmoke">
        © Itamat 2024
      </li>
      <li>
        <a
          href="https://itamat.it/privacy"
          target="_blank"
        >
          Informativa sulla privacy
        </a>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  props: {
    menu: { type: Array, default: () => [] },
    social: { type: Array, default: () => [] },
  },
  data() {
    return {
      shareURL: window.location.origin,
      showClipboardInfo: false,
      infoTimeout: 2000,
    };
  },
  methods: {
    getMessage(item) {
      return item.label === 'clipboard' ? item.message : '';
    },
    getSocialClass(item) {
      return {
        'show-info': item.label === 'clipboard' && this.showClipboardInfo,
      };
    },
    // https://stackoverflow.com/a/16861050/1724106
    getPopupDimensions(w = 800, h = 600) {
      const {
        screenLeft, screenTop, innerWidth, innerHeight,
      } = window;
      const dualScreenLeft = screenLeft || screen.left;
      const dualScreenTop = screenTop || screen.top;
      const width = innerWidth || document.documentElement.clientWidth || screen.width;
      const height = innerHeight || document.documentElement.clientHeight || screen.height;

      return {
        width: w,
        height: h,
        left: width / 2 - w / 2 + dualScreenLeft,
        top: height / 2 - h / 2 + dualScreenTop,
      };
    },
    constructTwitterURL(message) {
      const { text, hashtags } = message;
      const url = 'https://twitter.com/intent/tweet';
      return `${url}?text=${encodeURIComponent(text)}&hashtags=${hashtags}&url=${this.shareURL}`;
    },
    share(social) {
      switch (social.label.toLowerCase()) {
        case 'twitter':
          return this.shareViaTwitter(social.message);
        case 'facebook':
          return this.shareViaFacebook(social.message);
        case 'clipboard':
          return this.copyToClipboard(social.message);
        default:
          return null;
      }
    },
    shareViaTwitter(message) {
      const frameName = 'Post a Tweet on Twitter';
      const {
        width, height, left, top,
      } = this.getPopupDimensions();
      const options = `resizable,scrollbars,width=${width},height=${height},top=${top},left=${left}`;
      const popup = window.open(this.constructTwitterURL(message), frameName, options);
      if (window.focus) {
        popup.focus();
      }
    },
    shareViaFacebook() {
      FB.ui(
        {
          method: 'share',
          display: 'popup',
          href: this.shareURL,
        },
        () => {},
      );
    },
    copyToClipboard() {
      const $textarea = document.createElement('textarea');
      $textarea.value = this.shareURL;
      document.body.appendChild($textarea);
      $textarea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.showClipboardInfo = true;
          setTimeout(() => {
            this.showClipboardInfo = false;
          }, this.infoTimeout);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Oops, unable to copy', error);
      }

      document.body.removeChild($textarea);
    },
  },
};
</script>

<style lang="postcss" scoped>
  footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: calc(var(--small-gap) / 2);
    flex-direction: column;
    width: 100vw;
    padding-top: 1em;
    background-color: #082b8c;

    & ul {
      list-style: none;
    }
  }

  svg {
    fill: white;
    margin-left: 10px;
    margin-right: 10px;
    height: 22px;
    width: 22px;
  }

  .footer-menu {
    display: flex;
    font-size: var(--font-size-small);
    padding: calc(var(--small-gap) / 2);
    border-radius: var(--border-radius);

    & a {
      color: whitesmoke;

      &:hover {
        color: grey;
      }

      &:focus {
        color: cyan;
      }
    }

    & li:not(:first-child) {
      margin-left: var(--base-gap);
    }
  }

  .footer-donation {
    display: flex;
    margin-bottom: 1em;
    @media (max-width: 1050px) {
      display: flex;
      justify-content: center;
    }
  }

  .footer-social {
    display: flex;

    @media (max-width: 1050px) {
      display: flex;
      justify-content: center;
    }

    & li {
      position: relative;

      &.show-info::before {
        transform: translate(-105%, -50%) scale(1);
        opacity: 1;

        @media (max-width: 1050px) {
          transform: translate(-50%, -55%) scale(1);
        }
      }
    }

    & li::before {
      content: attr(data-message);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-105%, -50%) scale(0);
      white-space: pre;
      padding: 10px 20px;
      border-radius: var(--border-radius);
      background: rgba(0, 0, 0, 0.3);
      width: auto;
      font-size: var(--font-size-small);
      opacity: 0;
      transition: opacity 150ms var(--ease-out-back);

      @media (max-width: 1050px) {
        left: 50%;
        top: -50%;
      }
    }

    & li:not(:last-child) {
      margin-right: 5px;
    }

    & button {
      --social-btn-size: 40px;
      width: var(--social-btn-size);
      height: var(--social-btn-size);
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover svg,
      &:hover svg path {
        stroke: var(--orange);
      }
    }
  }
</style>
