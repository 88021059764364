import { DEFAULT_LOCALE } from '@/config';
// import i18n from '@/i18n';
import { storageAvailable } from '@/helper/storage';
import { getTranslatedTitles, getTranslatedAliases } from '@/i18n/helper';
import localI18n from './i18n';

function hasAnswers(to, from, next) {
  if (
    !to.params.result
    && storageAvailable('sessionStorage') // TODO: use localStorage
    && !sessionStorage.getItem('itamat-answers')
  ) {
    // next({ path: `/${i18n.locale}/` });
  }
  next();
}

export default [
  {
    path: localI18n[DEFAULT_LOCALE].theses.url,
    alias: getTranslatedAliases(localI18n, 'theses'),
    component: () => import('./components/Itamat.vue' /* webpackChunkName: "itamat" */),
    children: [
      {
        path: '',
        name: 'theses',
        component: () => import('./components/Theses.vue' /* webpackChunkName: "itamat" */),
        meta: {
          title: getTranslatedTitles(localI18n, 'theses'),
        },
      },
      {
        path: localI18n[DEFAULT_LOCALE].emphasis.url,
        alias: getTranslatedAliases(localI18n, 'emphasis'),
        name: 'emphasis',
        component: () => import('./components/Emphasis.vue' /* webpackChunkName: "itamat" */),
        beforeEnter: hasAnswers,
        meta: {
          title: getTranslatedTitles(localI18n, 'emphasis'),
        },
      },
      {
        path: localI18n[DEFAULT_LOCALE].results.url,
        component: () => import('./components/Results.vue' /* webpackChunkName: "itamat" */),
        beforeEnter: hasAnswers,
        meta: {
          title: getTranslatedTitles(localI18n, 'results'),
        },
        children: [
          {
            path: '',
            name: 'defaultResults', // Give this child route a name
            component: () => import('./components/Results.vue' /* webpackChunkName: "itamat" */),
            meta: {
              title: getTranslatedTitles(localI18n, 'results'),
            },
          },
          {
            path: ':result',
            name: 'detailedResults', // Give this child route a name
            component: () => import('./components/Results.vue' /* webpackChunkName: "itamat" */),
            meta: {
              title: getTranslatedTitles(localI18n, 'results'),
            },
          },
        ],
      },
    ],
  },
];
