<template>
  <div v-html="renderedMarkdown" />
</template>

<script>
import MarkdownIt from 'markdown-it';

export default {
  name: 'MarkdownRenderer',
  props: {
    source: { type: String, required: true },
  },
  data() {
    return {
      md: new MarkdownIt(),
    };
  },
  computed: {
    renderedMarkdown() {
      return this.md.render(this.source);
    },
  },
};
</script>
